
import AcademyGlintstoneStaffIcon from '../../../icons/eldenringitems/AcademyGlintstoneStaff.png';
import AlabasterLordsSwordIcon from '../../../icons/eldenringitems/AlabasterLordsSword.png';
import AlbinauricBowIcon from '../../../icons/eldenringitems/AlbinauricBow.png';
import AlbinauricShieldIcon from '../../../icons/eldenringitems/AlbinauricShield.png';
import AlbinauricStaffIcon from '../../../icons/eldenringitems/AlbinauricStaff.png';
import AncientMeteoricOreGreatswordIcon from '../../../icons/eldenringitems/AncientMeteoricOreGrea.png';
import AnsbachsLongbowIcon from '../../../icons/eldenringitems/AnsbachsLongbow.png';
import AnspurRapierIcon from '../../../icons/eldenringitems/AnspurRapier.png';
import AntsSkullPlateIcon from '../../../icons/eldenringitems/AntsSkullPlate.png';
import AnvilHammerIcon from '../../../icons/eldenringitems/AnvilHammer.png';
import ArbalestIcon from '../../../icons/eldenringitems/Arbalest.png';
import AstrologersStaffIcon from '../../../icons/eldenringitems/AstrologersStaff.png';
import AxeOfGodfreyIcon from '../../../icons/eldenringitems/AxeOfGodfrey.png';
import AxeOfGodrickIcon from '../../../icons/eldenringitems/AxeOfGodrick.png';
import AzursGlintstoneStaffIcon from '../../../icons/eldenringitems/AzursGlintstoneStaff.png';
import BackhandBladeIcon from '../../../icons/eldenringitems/BackhandBlade.png';
import BanditsCurvedSwordIcon from '../../../icons/eldenringitems/BanditsCurvedSword.png';
import BanishedKnightsGreatswordIcon from '../../../icons/eldenringitems/BanishedKnightsGreatsw.png';
import BanishedKnightsHalberdIcon from '../../../icons/eldenringitems/BanishedKnightsHalberd.png';
import BanishedKnightsShieldIcon from '../../../icons/eldenringitems/BanishedKnightsShield.png';
import BarbedStaffSpearIcon from '../../../icons/eldenringitems/BarbedStaffSpear.png';
import BastardsStarsIcon from '../../../icons/eldenringitems/BastardsStars.png';
import BastardSwordIcon from '../../../icons/eldenringitems/BastardSword.png';
import BattleAxeIcon from '../../../icons/eldenringitems/BattleAxe.png';
import BattleHammerIcon from '../../../icons/eldenringitems/BattleHammer.png';
import BeastClawIcon from '../../../icons/eldenringitems/BeastClaw.png';
import BeastclawGreathammerIcon from '../../../icons/eldenringitems/BeastclawGreathammer.png';
import BeastCrestHeaterShieldIcon from '../../../icons/eldenringitems/BeastCrestHeaterShield.png';
import BeastmansCleaverIcon from '../../../icons/eldenringitems/BeastmansCleaver.png';
import BeastmansCurvedSwordIcon from '../../../icons/eldenringitems/BeastmansCurvedSword.png';
import BeastmansJarShieldIcon from '../../../icons/eldenringitems/BeastmansJarShield.png';
import BeastRepellentTorchIcon from '../../../icons/eldenringitems/BeastRepellentTorch.png';
import BlackBowIcon from '../../../icons/eldenringitems/BlackBow.png';
import BlackKnifeIcon from '../../../icons/eldenringitems/BlackKnife.png';
import BlackLeatherShieldIcon from '../../../icons/eldenringitems/BlackLeatherShield.png';
import BlackSteelGreathammerIcon from '../../../icons/eldenringitems/BlackSteelGreathammer.png';
import BlackSteelGreatshieldIcon from '../../../icons/eldenringitems/BlackSteelGreatshield.png';
import BlackSteelTwinbladeIcon from '../../../icons/eldenringitems/BlackSteelTwinblade.png';
import BladeOfCallingIcon from '../../../icons/eldenringitems/BladeOfCalling.png';
import BlasphemousBladeIcon from '../../../icons/eldenringitems/BlasphemousBlade.png';
import BloodfiendsArmIcon from '../../../icons/eldenringitems/BloodfiendsArm.png';
import BloodfiendsForkIcon from '../../../icons/eldenringitems/BloodfiendsFork.png';
import BloodfiendsSacredSpearIcon from '../../../icons/eldenringitems/BloodfiendsSacredSpear.png';
import BloodhoundClawsIcon from '../../../icons/eldenringitems/BloodhoundClaws.png';
import BloodhoundsFangIcon from '../../../icons/eldenringitems/BloodhoundsFang.png';
import BloodstainedDaggerIcon from '../../../icons/eldenringitems/BloodstainedDagger.png';
import BloodyHeliceIcon from '../../../icons/eldenringitems/BloodyHelice.png';
import BlueCrestHeaterShieldIcon from '../../../icons/eldenringitems/BlueCrestHeaterShield.png';
import BlueGoldKiteShieldIcon from '../../../icons/eldenringitems/BlueGoldKiteShield.png';
import BlueWhiteWoodenShieldIcon from '../../../icons/eldenringitems/BlueWhiteWoodenShield.png';
import BoltOfGransaxIcon from '../../../icons/eldenringitems/BoltOfGransax.png';
import BoneBowIcon from '../../../icons/eldenringitems/BoneBow.png';
import BrassShieldIcon from '../../../icons/eldenringitems/BrassShield.png';
import BriarGreatshieldIcon from '../../../icons/eldenringitems/BriarGreatshield.png';
import BrickHammerIcon from '../../../icons/eldenringitems/BrickHammer.png';
import BroadswordIcon from '../../../icons/eldenringitems/Broadsword.png';
import BucklerIcon from '../../../icons/eldenringitems/Buckler.png';
import ButcheringKnifeIcon from '../../../icons/eldenringitems/ButcheringKnife.png';
import CaestusIcon from '../../../icons/eldenringitems/Caestus.png';
import CandletreeWoodenShieldIcon from '../../../icons/eldenringitems/CandletreeWoodenShield.png';
import CaneSwordIcon from '../../../icons/eldenringitems/CaneSword.png';
import CarianGlintbladeStaffIcon from '../../../icons/eldenringitems/CarianGlintbladeStaff.png';
import CarianGlintstoneStaffIcon from '../../../icons/eldenringitems/CarianGlintstoneStaff.png';
import CarianKnightsShieldIcon from '../../../icons/eldenringitems/CarianKnightsShield.png';
import CarianKnightsSwordIcon from '../../../icons/eldenringitems/CarianKnightsSword.png';
import CarianRegalScepterIcon from '../../../icons/eldenringitems/CarianRegalScepter.png';
import CarianSorcerySwordIcon from '../../../icons/eldenringitems/CarianSorcerySword.png';
import CarianThrustingShieldIcon from '../../../icons/eldenringitems/CarianThrustingShield.png';
import CelebrantsCleaverIcon from '../../../icons/eldenringitems/CelebrantsCleaver.png';
import CelebrantsRibRakeIcon from '../../../icons/eldenringitems/CelebrantsRibRake.png';
import CelebrantsSickleIcon from '../../../icons/eldenringitems/CelebrantsSickle.png';
import CelebrantsSkullIcon from '../../../icons/eldenringitems/CelebrantsSkull.png';
import ChainlinkFlailIcon from '../../../icons/eldenringitems/ChainlinkFlail.png';
import ChillingPerfumeBottleIcon from '../../../icons/eldenringitems/ChillingPerfumeBottle.png';
import CinquedeaIcon from '../../../icons/eldenringitems/Cinquedea.png';
import CipherPataIcon from '../../../icons/eldenringitems/CipherPata.png';
import ClawmarkSealIcon from '../../../icons/eldenringitems/ClawmarkSeal.png';
import ClawsOfNightIcon from '../../../icons/eldenringitems/ClawsOfNight.png';
import ClaymansHarpoonIcon from '../../../icons/eldenringitems/ClaymansHarpoon.png';
import ClaymoreIcon from '../../../icons/eldenringitems/Claymore.png';
import CleanrotKnightsSwordIcon from '../../../icons/eldenringitems/CleanrotKnightsSword.png';
import CleanrotSpearIcon from '../../../icons/eldenringitems/CleanrotSpear.png';
import ClingingBoneIcon from '../../../icons/eldenringitems/ClingingBone.png';
import ClubIcon from '../../../icons/eldenringitems/Club.png';
import CodedSwordIcon from '../../../icons/eldenringitems/CodedSword.png';
import CoilShieldIcon from '../../../icons/eldenringitems/CoilShield.png';
import CommandersStandardIcon from '../../../icons/eldenringitems/CommandersStandard.png';
import CompositeBowIcon from '../../../icons/eldenringitems/CompositeBow.png';
import CranialVesselCandlestandIcon from '../../../icons/eldenringitems/CranialVesselCandlesta.png';
import CrepussBlackKeyCrossbowIcon from '../../../icons/eldenringitems/CrepussBlackKeyCrossbow.png';
import CrescentMoonAxeIcon from '../../../icons/eldenringitems/CrescentMoonAxe.png';
import CrossedTreeTowershieldIcon from '../../../icons/eldenringitems/CrossedTreeTowershield.png';
import CrossNaginataIcon from '../../../icons/eldenringitems/CrossNaginata.png';
import CrucibleHornshieldIcon from '../../../icons/eldenringitems/CrucibleHornshield.png';
import CrystalKnifeIcon from '../../../icons/eldenringitems/CrystalKnife.png';
import CrystalSpearIcon from '../../../icons/eldenringitems/CrystalSpear.png';
import CrystalStaffIcon from '../../../icons/eldenringitems/CrystalStaff.png';
import CrystalSwordIcon from '../../../icons/eldenringitems/CrystalSword.png';
import CuckooGreatshieldIcon from '../../../icons/eldenringitems/CuckooGreatshield.png';
import CursebladesCirqueIcon from '../../../icons/eldenringitems/CursebladesCirque.png';
import CurvedClubIcon from '../../../icons/eldenringitems/CurvedClub.png';
import CurvedGreatClubIcon from '../../../icons/eldenringitems/CurvedGreatClub.png';
import DaggerIcon from '../../../icons/eldenringitems/Dagger.png';
import DancingBladeOfRanahIcon from '../../../icons/eldenringitems/DancingBladeOfRanah.png';
import DanesFootworkIcon from '../../../icons/eldenringitems/DanesFootwork.png';
import DarkMoonGreatswordIcon from '../../../icons/eldenringitems/DarkMoonGreatsword.png';
import DeadlyPoisonPerfumeBottleIcon from '../../../icons/eldenringitems/DeadlyPoisonPerfumeBottle.png';
import DeathKnightsLonghaftAxeIcon from '../../../icons/eldenringitems/DeathKnightsLonghaftAx.png';
import DeathKnightsTwinAxesIcon from '../../../icons/eldenringitems/DeathKnightsTwinAxes.png';
import DeathRitualSpearIcon from '../../../icons/eldenringitems/DeathRitualSpear.png';
import DeathsPokerIcon from '../../../icons/eldenringitems/DeathsPoker.png';
import DemiHumanQueensStaffIcon from '../../../icons/eldenringitems/DemiHumanQueensStaff.png';
import DevoniasHammerIcon from '../../../icons/eldenringitems/DevoniasHammer.png';
import DevourersScepterIcon from '../../../icons/eldenringitems/DevourersScepter.png';
import DiggersStaffIcon from '../../../icons/eldenringitems/DiggersStaff.png';
import DismounterIcon from '../../../icons/eldenringitems/Dismounter.png';
import DistinguishedGreatshieldIcon from '../../../icons/eldenringitems/DistinguishedGreatshie.png';
import DragonclawShieldIcon from '../../../icons/eldenringitems/DragonclawShield.png';
import DragonCommunionSealIcon from '../../../icons/eldenringitems/DragonCommunionSeal.png';
import DragonGreatclawIcon from '../../../icons/eldenringitems/DragonGreatclaw.png';
import DragonHalberdIcon from '../../../icons/eldenringitems/DragonHalberd.png';
import DragonHuntersGreatKatanaIcon from '../../../icons/eldenringitems/DragonHuntersGreatKatana.png';
import DragonKingsCragbladeIcon from '../../../icons/eldenringitems/DragonKingsCragblade.png';
import DragonscaleBladeIcon from '../../../icons/eldenringitems/DragonscaleBlade.png';
import DragonTowershieldIcon from '../../../icons/eldenringitems/DragonTowershield.png';
import DryleafArtsIcon from '../../../icons/eldenringitems/DryleafArts.png';
import DryleafSealIcon from '../../../icons/eldenringitems/DryleafSeal.png';
import DuelingShieldIcon from '../../../icons/eldenringitems/DuelingShield.png';
import DuelistGreataxeIcon from '../../../icons/eldenringitems/DuelistGreataxe.png';
import EclipseCrestGreatshieldIcon from '../../../icons/eldenringitems/EclipseCrestGreatshiel.png';
import EclipseCrestHeaterShieldIcon from '../../../icons/eldenringitems/EclipseCrestHeaterShie.png';
import EclipseShotelIcon from '../../../icons/eldenringitems/EclipseShotel.png';
import EleonorasPolebladeIcon from '../../../icons/eldenringitems/EleonorasPoleblade.png';
import EnvoysGreathornIcon from '../../../icons/eldenringitems/EnvoysGreathorn.png';
import EnvoysHornIcon from '../../../icons/eldenringitems/EnvoysHorn.png';
import EnvoysLongHornIcon from '../../../icons/eldenringitems/EnvoysLongHorn.png';
import ErdsteelDaggerIcon from '../../../icons/eldenringitems/ErdsteelDagger.png';
import ErdtreeBowIcon from '../../../icons/eldenringitems/ErdtreeBow.png';
import ErdtreeGreatbowIcon from '../../../icons/eldenringitems/ErdtreeGreatbow.png';
import ErdtreeGreatshieldIcon from '../../../icons/eldenringitems/ErdtreeGreatshield.png';
import ErdtreeSealIcon from '../../../icons/eldenringitems/ErdtreeSeal.png';
import EstocIcon from '../../../icons/eldenringitems/Estoc.png';
import EuporiaIcon from '../../../icons/eldenringitems/Euporia.png';
import ExecutionersGreataxeIcon from '../../../icons/eldenringitems/ExecutionersGreataxe.png';
import FalchionIcon from '../../../icons/eldenringitems/Falchion.png';
import FallingstarBeastJawIcon from '../../../icons/eldenringitems/FallingstarBeastJaw.png';
import FalxIcon from '../../../icons/eldenringitems/Falx.png';
import FamilyHeadsIcon from '../../../icons/eldenringitems/FamilyHeads.png';
import FingerprintStoneShieldIcon from '../../../icons/eldenringitems/FingerprintStoneShield.png';
import FingerSealIcon from '../../../icons/eldenringitems/FingerSeal.png';
import FireKnightsGreatswordIcon from '../../../icons/eldenringitems/FireKnightsGreatsword.png';
import FireKnightsSealIcon from '../../../icons/eldenringitems/FireKnightsSeal.png';
import FireKnightsShortswordIcon from '../../../icons/eldenringitems/FireKnightsShortsword.png';
import FiresparkPerfumeBottleIcon from '../../../icons/eldenringitems/FiresparkPerfumeBottle.png';
import FlailIcon from '../../../icons/eldenringitems/Flail.png';
import FlambergeIcon from '../../../icons/eldenringitems/Flamberge.png';
import FlameCrestWoodenShieldIcon from '../../../icons/eldenringitems/FlameCrestWoodenShield.png';
import FlowerstoneGavelIcon from '../../../icons/eldenringitems/FlowerstoneGavel.png';
import FlowingCurvedSwordIcon from '../../../icons/eldenringitems/FlowingCurvedSword.png';
import ForkedHatchetIcon from '../../../icons/eldenringitems/ForkedHatchet.png';
import ForkedTongueHatchetIcon from '../../../icons/eldenringitems/ForkedTongueHatchet.png';
import FrenziedFlameSealIcon from '../../../icons/eldenringitems/FrenziedFlameSeal.png';
import FrenzyflamePerfumeBottleIcon from '../../../icons/eldenringitems/FrenzyflamePerfumeBottle.png';
import FreyjasGreatswordIcon from '../../../icons/eldenringitems/FreyjasGreatsword.png';
import FrozenNeedleIcon from '../../../icons/eldenringitems/FrozenNeedle.png';
import FullMoonCrossbowIcon from '../../../icons/eldenringitems/FullMoonCrossbow.png';
import GargoylesBlackAxeIcon from '../../../icons/eldenringitems/GargoylesBlackAxe.png';
import GargoylesBlackbladeIcon from '../../../icons/eldenringitems/GargoylesBlackblade.png';
import GargoylesBlackBladesIcon from '../../../icons/eldenringitems/GargoylesBlackBlades.png';
import GargoylesBlackHalberdIcon from '../../../icons/eldenringitems/GargoylesBlackHalberd.png';
import GargoylesGreatAxeIcon from '../../../icons/eldenringitems/GargoylesGreatAxe.png';
import GargoylesGreatswordIcon from '../../../icons/eldenringitems/GargoylesGreatsword.png';
import GargoylesHalberdIcon from '../../../icons/eldenringitems/GargoylesHalberd.png';
import GargoylesTwinbladeIcon from '../../../icons/eldenringitems/GargoylesTwinblade.png';
import GazingFingerIcon from '../../../icons/eldenringitems/GazingFinger.png';
import GelmirGlintstoneStaffIcon from '../../../icons/eldenringitems/GelmirGlintstoneStaff.png';
import GhizasWheelIcon from '../../../icons/eldenringitems/GhizasWheel.png';
import GhostflameTorchIcon from '../../../icons/eldenringitems/GhostflameTorch.png';
import GiantCrusherIcon from '../../../icons/eldenringitems/GiantCrusher.png';
import GiantsRedBraidIcon from '../../../icons/eldenringitems/GiantsRedBraid.png';
import GiantsSealIcon from '../../../icons/eldenringitems/GiantsSeal.png';
import GildedGreatshieldIcon from '../../../icons/eldenringitems/GildedGreatshield.png';
import GildedIronShieldIcon from '../../../icons/eldenringitems/GildedIronShield.png';
import GlaiveIcon from '../../../icons/eldenringitems/Glaive.png';
import GlintstoneKrisIcon from '../../../icons/eldenringitems/GlintstoneKris.png';
import GlintstoneStaffIcon from '../../../icons/eldenringitems/GlintstoneStaff.png';
import GodskinPeelerIcon from '../../../icons/eldenringitems/GodskinPeeler.png';
import GodskinStitcherIcon from '../../../icons/eldenringitems/GodskinStitcher.png';
import GodslayersGreatswordIcon from '../../../icons/eldenringitems/GodslayersGreatsword.png';
import GodslayersSealIcon from '../../../icons/eldenringitems/GodslayersSeal.png';
import GoldenBeastCrestShieldIcon from '../../../icons/eldenringitems/GoldenBeastCrestShield.png';
import GoldenEpitaphIcon from '../../../icons/eldenringitems/GoldenEpitaph.png';
import GoldenGreatshieldIcon from '../../../icons/eldenringitems/GoldenGreatshield.png';
import GoldenHalberdIcon from '../../../icons/eldenringitems/GoldenHalberd.png';
import GoldenLionShieldIcon from '../../../icons/eldenringitems/GoldenLionShield.png';
import GoldenOrderGreatswordIcon from '../../../icons/eldenringitems/GoldenOrderGreatsword.png';
import GoldenOrderSealIcon from '../../../icons/eldenringitems/GoldenOrderSeal.png';
import GolemFistIcon from '../../../icons/eldenringitems/GolemFist.png';
import GolemGreatbowIcon from '../../../icons/eldenringitems/GolemGreatbow.png';
import GolemsHalberdIcon from '../../../icons/eldenringitems/GolemsHalberd.png';
import GraftedBladeGreatswordIcon from '../../../icons/eldenringitems/GraftedBladeGreatsword.png';
import GraftedDragonIcon from '../../../icons/eldenringitems/GraftedDragon.png';
import GravelStoneSealIcon from '../../../icons/eldenringitems/GravelStoneSeal.png';
import GraveScytheIcon from '../../../icons/eldenringitems/GraveScythe.png';
import GreataxeIcon from '../../../icons/eldenringitems/Greataxe.png';
import GreatbowIcon from '../../../icons/eldenringitems/Greatbow.png';
import GreatClubIcon from '../../../icons/eldenringitems/GreatClub.png';
import GreatEpeeIcon from '../../../icons/eldenringitems/GreatEpee.png';
import GreathornHammerIcon from '../../../icons/eldenringitems/GreathornHammer.png';
import GreatKatanaIcon from '../../../icons/eldenringitems/GreatKatana.png';
import GreatKnifeIcon from '../../../icons/eldenringitems/GreatKnife.png';
import GreatMaceIcon from '../../../icons/eldenringitems/GreatMace.png';
import GreatOmenkillerCleaverIcon from '../../../icons/eldenringitems/GreatOmenkillerCleaver.png';
import GreatStarsIcon from '../../../icons/eldenringitems/GreatStars.png';
import GreatswordIcon from '../../../icons/eldenringitems/Greatsword.png';
import GreatswordOfDamnationIcon from '../../../icons/eldenringitems/GreatswordOfDamnation.png';
import GreatswordOfRadhanIcon from '../../../icons/eldenringitems/GreatswordOfRadhan.png';
import GreatswordOfSolitudeIcon from '../../../icons/eldenringitems/GreatswordOfSolitude.png';
import GreatTurtleShellIcon from '../../../icons/eldenringitems/GreatTurtleShell.png';
import GrossmesserIcon from '../../../icons/eldenringitems/Grossmesser.png';
import GuardiansSwordspearIcon from '../../../icons/eldenringitems/GuardiansSwordspear.png';
import HailigtreeCrestGreatshieldIcon from '../../../icons/eldenringitems/HailigtreeCrestGreatsh.png';
import HalberdIcon from '../../../icons/eldenringitems/Halberd.png';
import HaloScytheIcon from '../../../icons/eldenringitems/HaloScythe.png';
import HammerIcon from '../../../icons/eldenringitems/Hammer.png';
import HandAxeIcon from '../../../icons/eldenringitems/HandAxe.png';
import HandBallistaIcon from '../../../icons/eldenringitems/HandBallista.png';
import HandOfMaleniaIcon from '../../../icons/eldenringitems/HandOfMalenia.png';
import HarpBowIcon from '../../../icons/eldenringitems/HarpBow.png';
import HawkCrestWoodenShieldIcon from '../../../icons/eldenringitems/HawkCrestWoodenShield.png';
import HeaterShieldIcon from '../../../icons/eldenringitems/HeaterShield.png';
import HeavyCrossbowIcon from '../../../icons/eldenringitems/HeavyCrossbow.png';
import HelphensSteepleIcon from '../../../icons/eldenringitems/HelphensSteeple.png';
import HighlandAxeIcon from '../../../icons/eldenringitems/HighlandAxe.png';
import HookclawsIcon from '../../../icons/eldenringitems/Hookclaws.png';
import HornBowIcon from '../../../icons/eldenringitems/HornBow.png';
import HornedWarriorsGreatswordIcon from '../../../icons/eldenringitems/HornedWarriorsGreatswo.png';
import HornedWarriorsSwordIcon from '../../../icons/eldenringitems/HornedWarriorsSword.png';
import HorseCrestWoodenShieldIcon from '../../../icons/eldenringitems/HorseCrestWoodenShield.png';
import HoslowsPetalWhipIcon from '../../../icons/eldenringitems/HoslowsPetalWhip.png';
import IceCrestShieldIcon from '../../../icons/eldenringitems/IceCrestShield.png';
import IcerindHatchetIcon from '../../../icons/eldenringitems/IcerindHatchet.png';
import IconShieldIcon from '../../../icons/eldenringitems/IconShield.png';
import IgonsGreatbowIcon from '../../../icons/eldenringitems/IgonsGreatbow.png';
import InquisitorsGirandoleIcon from '../../../icons/eldenringitems/InquisitorsGirandole.png';
import InvertedHawkTowershieldIcon from '../../../icons/eldenringitems/InvertedHawkTowershiel.png';
import InvertedHawnHeaterShieldIcon from '../../../icons/eldenringitems/InvertedHawnHeaterShie.png';
import IronBallIcon from '../../../icons/eldenringitems/IronBall.png';
import IronCleaverIcon from '../../../icons/eldenringitems/IronCleaver.png';
import IronGreatswordIcon from '../../../icons/eldenringitems/IronGreatsword.png';
import IronRoundshieldIcon from '../../../icons/eldenringitems/IronRoundshield.png';
import IronSpearIcon from '../../../icons/eldenringitems/IronSpear.png';
import IvorySickleIcon from '../../../icons/eldenringitems/IvorySickle.png';
import JarCannonIcon from '../../../icons/eldenringitems/JarCannon.png';
import JawboneAxeIcon from '../../../icons/eldenringitems/JawboneAxe.png';
import JellyfishShieldIcon from '../../../icons/eldenringitems/JellyfishShield.png';
import KatarIcon from '../../../icons/eldenringitems/Katar.png';
import KiteShieldIcon from '../../../icons/eldenringitems/KiteShield.png';
import KnightsGreatswordIcon from '../../../icons/eldenringitems/KnightsGreatsword.png';
import LamentingVisageIcon from '../../../icons/eldenringitems/LamentingVisage.png';
import LanceIcon from '../../../icons/eldenringitems/Lance.png';
import LargeClubIcon from '../../../icons/eldenringitems/LargeClub.png';
import LargeLeatherShieldIcon from '../../../icons/eldenringitems/LargeLeatherShield.png';
import LazuliGlintstoneSwordIcon from '../../../icons/eldenringitems/LazuliGlintstoneSword.png';
import LedasSwordIcon from '../../../icons/eldenringitems/LedasSword.png';
import LightCrossbowIcon from '../../../icons/eldenringitems/LightCrossbow.png';
import LightningPerfumeBottleIcon from '../../../icons/eldenringitems/LightningPerfumeBottle.png';
import LionGreatbowIcon from '../../../icons/eldenringitems/LionGreatbow.png';
import LizardGreatswordIcon from '../../../icons/eldenringitems/LizardGreatsword.png';
import LongbowIcon from '../../../icons/eldenringitems/Longbow.png';
import LongshaftAxeIcon from '../../../icons/eldenringitems/LongshaftAxe.png';
import LongswordIcon from '../../../icons/eldenringitems/Longsword.png';
import LordswornsGreatswordIcon from '../../../icons/eldenringitems/LordswornsGreatsword.png';
import LordswornsShieldIcon from '../../../icons/eldenringitems/LordswornsShield.png';
import LordswornsStraightSwordIcon from '../../../icons/eldenringitems/LordswornsStraightSwor.png';
import LorettasWarSickleIcon from '../../../icons/eldenringitems/LorettasWarSickle.png';
import LucerneIcon from '../../../icons/eldenringitems/Lucerne.png';
import LusatsGlintstoneStaffIcon from '../../../icons/eldenringitems/LusatsGlintstoneStaff.png';
import MaceIcon from '../../../icons/eldenringitems/Mace.png';
import MaddingHandIcon from '../../../icons/eldenringitems/MaddingHand.png';
import MagmaBladeIcon from '../../../icons/eldenringitems/MagmaBlade.png';
import MagmaWhipCandlestickIcon from '../../../icons/eldenringitems/MagmaWhipCandlestick.png';
import MagmaWyrmsScaleswordIcon from '../../../icons/eldenringitems/MagmaWyrmsScalesword.png';
import MainGaucheIcon from '../../../icons/eldenringitems/MainGauche.png';
import MalikethsBlackBladeIcon from '../../../icons/eldenringitems/MalikethsBlackBlade.png';
import ManorTowershieldIcon from '../../../icons/eldenringitems/ManorTowershield.png';
import ManSerpentsShieldIcon from '../../../icons/eldenringitems/ManSerpentsShield.png';
import MantisBladeIcon from '../../../icons/eldenringitems/MantisBlade.png';
import MaraisExecutionersSwordIcon from '../../../icons/eldenringitems/MaraisExecutionersSwor.png';
import MarikasHammerIcon from '../../../icons/eldenringitems/MarikasHammer.png';
import MarredLeatherShieldIcon from '../../../icons/eldenringitems/MarredLeatherShield.png';
import MarredWoodenShieldIcon from '../../../icons/eldenringitems/MarredWoodenShield.png';
import MaternalStaffIcon from '../../../icons/eldenringitems/MaternalStaff.png';
import MessmerSoldiersAxeIcon from '../../../icons/eldenringitems/MessmerSoldiersAxe.png';
import MessmerSoldierShieldIcon from '../../../icons/eldenringitems/MessmerSoldierShield.png';
import MessmerSoldiersSpearIcon from '../../../icons/eldenringitems/MessmerSoldiersSpear.png';
import MeteoricOreBladeIcon from '../../../icons/eldenringitems/MeteoricOreBlade.png';
import MeteoriteStaffIcon from '../../../icons/eldenringitems/MeteoriteStaff.png';
import MiladyIcon from '../../../icons/eldenringitems/Milady.png';
import MiquellanKnightsSwordIcon from '../../../icons/eldenringitems/MiquellanKnightsSword.png';
import MisbegottenShortbowIcon from '../../../icons/eldenringitems/MisbegottenShortbow.png';
import MisericordeIcon from '../../../icons/eldenringitems/Misericorde.png';
import MohgwynsSacredSpearIcon from '../../../icons/eldenringitems/MohgwynsSacredSpear.png';
import MonksFlamemaceIcon from '../../../icons/eldenringitems/MonksFlamemace.png';
import MoonveilIcon from '../../../icons/eldenringitems/Moonveil.png';
import MorgottsCursedSwordIcon from '../../../icons/eldenringitems/MorgottsCursedSword.png';
import MorningStarIcon from '../../../icons/eldenringitems/MorningStar.png';
import NagakibaIcon from '../../../icons/eldenringitems/Nagakiba.png';
import NanayasTorchIcon from '../../../icons/eldenringitems/NanayasTorch.png';
import NightriderFlailIcon from '../../../icons/eldenringitems/NightriderFlail.png';
import NightriderGlaiveIcon from '../../../icons/eldenringitems/NightriderGlaive.png';
import NoblesEstocIcon from '../../../icons/eldenringitems/NoblesEstoc.png';
import NoblesSlenderSwordIcon from '../../../icons/eldenringitems/NoblesSlenderSword.png';
import NoxFlowingHammerIcon from '../../../icons/eldenringitems/NoxFlowingHammer.png';
import NoxFlowingSwordIcon from '../../../icons/eldenringitems/NoxFlowingSword.png';
import ObsidianLaminaIcon from '../../../icons/eldenringitems/ObsidianLamina.png';
import OmenCleaverIcon from '../../../icons/eldenringitems/OmenCleaver.png';
import OneEyedShieldIcon from '../../../icons/eldenringitems/OneEyedShield.png';
import OnyxLordsGreatswordIcon from '../../../icons/eldenringitems/OnyxLordsGreatsword.png';
import OrdovissGreatswordIcon from '../../../icons/eldenringitems/OrdovissGreatsword.png';
import OrnamentalStraightSwordIcon from '../../../icons/eldenringitems/OrnamentalStraightSwor.png';
import ParryingDaggerIcon from '../../../icons/eldenringitems/ParryingDagger.png';
import PartisanIcon from '../../../icons/eldenringitems/Partisan.png';
import PataIcon from '../../../icons/eldenringitems/Pata.png';
import PerfumersShieldIcon from '../../../icons/eldenringitems/PerfumersShield.png';
import PestsGlaiveIcon from '../../../icons/eldenringitems/PestsGlaive.png';
import PickaxeIcon from '../../../icons/eldenringitems/Pickaxe.png';
import PikeIcon from '../../../icons/eldenringitems/Pike.png';
import PilloryShieldIcon from '../../../icons/eldenringitems/PilloryShield.png';
import PoisonedHandIcon from '../../../icons/eldenringitems/PoisonedHand.png';
import PolebladeOfTheBudIcon from '../../../icons/eldenringitems/PolebladeOfTheBud.png';
import PrelatesInfernoCrozierIcon from '../../../icons/eldenringitems/PrelatesInfernoCrozier.png';
import PrinceOfDeathsStaffIcon from '../../../icons/eldenringitems/PrinceOfDeathsStaff.png';
import PulleyBowIcon from '../../../icons/eldenringitems/PulleyBow.png';
import PulleyCrossbowIcon from '../../../icons/eldenringitems/PulleyCrossbow.png';
import PutrescenceCleaverIcon from '../../../icons/eldenringitems/PutrescenceCleaver.png';
import QueelignsGreatswordIcon from '../../../icons/eldenringitems/QueelignsGreatsword.png';
import RabbathsCannonIcon from '../../../icons/eldenringitems/RabbathsCannon.png';
import RakshasasGreatKatanaIcon from '../../../icons/eldenringitems/RakshasasGreatKatana.png';
import RapierIcon from '../../../icons/eldenringitems/Rapier.png';
import RaptorTalonsIcon from '../../../icons/eldenringitems/RaptorTalons.png';
import RedBearsClawIcon from '../../../icons/eldenringitems/RedBearsClaw.png';
import RedBranchShortbowIcon from '../../../icons/eldenringitems/RedBranchShortbow.png';
import RedCrestHeaterShieldIcon from '../../../icons/eldenringitems/RedCrestHeaterShield.png';
import RedmaneGreatshieldIcon from '../../../icons/eldenringitems/RedmaneGreatshield.png';
import RedThornRoundshieldIcon from '../../../icons/eldenringitems/RedThornRoundshield.png';
import ReduviaIcon from '../../../icons/eldenringitems/Reduvia.png';
import RegaliaOfEochaidIcon from '../../../icons/eldenringitems/RegaliaOfEochaid.png';
import RellanasTwinBladesIcon from '../../../icons/eldenringitems/RellanasTwinBlades.png';
import RepeatingCrossbowIcon from '../../../icons/eldenringitems/RepeatingCrossbow.png';
import RicketyShieldIcon from '../../../icons/eldenringitems/RicketyShield.png';
import RiftShieldIcon from '../../../icons/eldenringitems/RiftShield.png';
import RingedFingerIcon from '../../../icons/eldenringitems/RingedFinger.png';
import RippleBladeIcon from '../../../icons/eldenringitems/RippleBlade.png';
import RippleCrescentHalberdIcon from '../../../icons/eldenringitems/RippleCrescentHalberd.png';
import RiversOfBloodIcon from '../../../icons/eldenringitems/RiversOfBlood.png';
import RivetedWoodenShieldIcon from '../../../icons/eldenringitems/RivetedWoodenShield.png';
import RogiersRapierIcon from '../../../icons/eldenringitems/RogiersRapier.png';
import RosusAxeIcon from '../../../icons/eldenringitems/RosusAxe.png';
import RottenBattleHammerIcon from '../../../icons/eldenringitems/RottenBattleHammer.png';
import RottenCrystalSpearIcon from '../../../icons/eldenringitems/RottenCrystalSpear.png';
import RottenCrystalStaffIcon from '../../../icons/eldenringitems/RottenCrystalStaff.png';
import RottenCrystalSwordIcon from '../../../icons/eldenringitems/RottenCrystalSword.png';
import RottenGreataxeIcon from '../../../icons/eldenringitems/RottenGreataxe.png';
import RottenStaffIcon from '../../../icons/eldenringitems/RottenStaff.png';
import RoundShieldIcon from '../../../icons/eldenringitems/RoundShield.png';
import RoyalGreatswordIcon from '../../../icons/eldenringitems/RoyalGreatsword.png';
import RuinsGreatswordIcon from '../../../icons/eldenringitems/RuinsGreatsword.png';
import RustedAnchorIcon from '../../../icons/eldenringitems/RustedAnchor.png';
import SacredRelicSwordIcon from '../../../icons/eldenringitems/SacredRelicSword.png';
import SacrificialAxeIcon from '../../../icons/eldenringitems/SacrificialAxe.png';
import ScavangersCurvedSwordIcon from '../../../icons/eldenringitems/ScavangersCurvedSword.png';
import ScepterOfTheAllKnowingIcon from '../../../icons/eldenringitems/ScepterOfTheAllKnowing.png';
import ScimitarIcon from '../../../icons/eldenringitems/Scimitar.png';
import ScorpionKiteShieldIcon from '../../../icons/eldenringitems/ScorpionKiteShield.png';
import ScorpionsStringerIcon from '../../../icons/eldenringitems/ScorpionsStringer.png';
import ScriptureWoodenShieldIcon from '../../../icons/eldenringitems/ScriptureWoodenShield.png';
import ScytheIcon from '../../../icons/eldenringitems/Scythe.png';
import SentrysTorchIcon from '../../../icons/eldenringitems/SentrysTorch.png';
import SerpentboneBladeIcon from '../../../icons/eldenringitems/SerpentboneBlade.png';
import SerpentBowIcon from '../../../icons/eldenringitems/SerpentBow.png';
import SerpentCrestShieldIcon from '../../../icons/eldenringitems/SerpentCrestShield.png';
import SerpentFlailIcon from '../../../icons/eldenringitems/SerpentFlail.png';
import SerpentGodCurvedSwordIcon from '../../../icons/eldenringitems/SerpentGodCurvedSword.png';
import SerpentHunterIcon from '../../../icons/eldenringitems/SerpentHunter.png';
import ShadowSunflowerBlossomIcon from '../../../icons/eldenringitems/ShadowSunflowerBlossom.png';
import ShamshirIcon from '../../../icons/eldenringitems/Shamshir.png';
import ShieldOfNightIcon from '../../../icons/eldenringitems/ShieldOfNight.png';
import ShieldOfTheGuiltyIcon from '../../../icons/eldenringitems/ShieldOfTheGuilty.png';
import ShortbowIcon from '../../../icons/eldenringitems/Shortbow.png';
import ShortSpearIcon from '../../../icons/eldenringitems/ShortSpear.png';
import ShortSwordIcon from '../../../icons/eldenringitems/ShortSword.png';
import ShotelIcon from '../../../icons/eldenringitems/Shotel.png';
import SiluriasTreeIcon from '../../../icons/eldenringitems/SiluriasTree.png';
import SilverMirrorshieldIcon from '../../../icons/eldenringitems/SilverMirrorshield.png';
import SmithscriptAxeIcon from '../../../icons/eldenringitems/SmithscriptAxe.png';
import SmithscriptCirqueIcon from '../../../icons/eldenringitems/SmithscriptCirque.png';
import SmithscriptDaggerIcon from '../../../icons/eldenringitems/SmithscriptDagger.png';
import SmithscriptGreathammerIcon from '../../../icons/eldenringitems/SmithscriptGreathammer.png';
import SmithscriptShieldIcon from '../../../icons/eldenringitems/SmithscriptShield.png';
import SmithscriptSpearIcon from '../../../icons/eldenringitems/SmithscriptSpear.png';
import SmolderingShieldIcon from '../../../icons/eldenringitems/SmolderingShield.png';
import SoldiersCrossbowIcon from '../../../icons/eldenringitems/SoldiersCrossbow.png';
import SpearIcon from '../../../icons/eldenringitems/Spear.png';
import SpearOfTheImpalerIcon from '../../../icons/eldenringitems/SpearOfTheImpaler.png';
import SpikedCaestusIcon from '../../../icons/eldenringitems/SpikedCaestus.png';
import SpikedClubIcon from '../../../icons/eldenringitems/SpikedClub.png';
import SpikedPalisadeShieldIcon from '../../../icons/eldenringitems/SpikedPalisadeShield.png';
import SpikedSpearIcon from '../../../icons/eldenringitems/SpikedSpear.png';
import SpiralhornShieldIcon from '../../../icons/eldenringitems/SpiralhornShield.png';
import SpiraltreeSealIcon from '../../../icons/eldenringitems/SpiraltreeSeal.png';
import SpiritGlaiveIcon from '../../../icons/eldenringitems/SpiritGlaive.png';
import SpiritSwordIcon from '../../../icons/eldenringitems/SpiritSword.png';
import SpreadCrossbowIcon from '../../../icons/eldenringitems/SpreadCrossbow.png';
import StaffOfLossIcon from '../../../icons/eldenringitems/StaffOfLoss.png';
import StaffOfTheAvatarIcon from '../../../icons/eldenringitems/StaffOfTheAvatar.png';
import StaffOfTheGreatBeyondIcon from '../../../icons/eldenringitems/StaffOfTheGreatBeyond.png';
import StaffOfTheGuiltyIcon from '../../../icons/eldenringitems/StaffOfTheGuilty.png';
import StarFistIcon from '../../../icons/eldenringitems/StarFist.png';
import StarLinedSwordIcon from '../../../icons/eldenringitems/StarLinedSword.png';
import StarscourgeGreatswordIcon from '../../../icons/eldenringitems/StarscourgeGreatsword.png';
import SteelWireTorchIcon from '../../../icons/eldenringitems/SteelWireTorch.png';
import StoneClubIcon from '../../../icons/eldenringitems/StoneClub.png';
import StoneSheathedSwordIcon from '../../../icons/eldenringitems/StoneSheathedSword.png';
import StormhawkAxeIcon from '../../../icons/eldenringitems/StormhawkAxe.png';
import StTrinasTorchIcon from '../../../icons/eldenringitems/StTrinasTorch.png';
import SunRealmShieldIcon from '../../../icons/eldenringitems/SunRealmShield.png';
import SwiftSpearIcon from '../../../icons/eldenringitems/SwiftSpear.png';
import SwordLanceIcon from '../../../icons/eldenringitems/SwordLance.png';
import SwordOfDarknessIcon from '../../../icons/eldenringitems/SwordOfDarkness.png';
import SwordOfLightIcon from '../../../icons/eldenringitems/SwordOfLight.png';
import SwordOfMilosIcon from '../../../icons/eldenringitems/SwordOfMilos.png';
import SwordOfNightAndFlameIcon from '../../../icons/eldenringitems/SwordOfNightAndFlame.png';
import SwordOfNnightIcon from '../../../icons/eldenringitems/SwordOfNnight.png';
import SwordOfStTrinaIcon from '../../../icons/eldenringitems/SwordOfStTrina.png';
import ThiolliersHiddenNeedleIcon from '../../../icons/eldenringitems/ThiolliersHiddenNeedle.png';
import ThornedWhipIcon from '../../../icons/eldenringitems/ThornedWhip.png';
import ToothWhipIcon from '../../../icons/eldenringitems/ToothWhip.png';
import TorchIcon from '../../../icons/eldenringitems/Torch.png';
import TorchpoleIcon from '../../../icons/eldenringitems/Torchpole.png';
import TreespearIcon from '../../../icons/eldenringitems/Treespear.png';
import TrollKnightsSwordIcon from '../../../icons/eldenringitems/TrollKnightsSword.png';
import TrollsGoldenSwordIcon from '../../../icons/eldenringitems/TrollsGoldenSword.png';
import TrollsHammerIcon from '../../../icons/eldenringitems/TrollsHammer.png';
import TwinbirdKiteShieldIcon from '../../../icons/eldenringitems/TwinbirdKiteShield.png';
import TwinbladeIcon from '../../../icons/eldenringitems/Twinblade.png';
import TwinnedKnightSwordsIcon from '../../../icons/eldenringitems/TwinnedKnightSwords.png';
import UchigatanaIcon from '../../../icons/eldenringitems/Uchigatana.png';
import UrumiIcon from '../../../icons/eldenringitems/Urumi.png';
import VarresBouquetIcon from '../../../icons/eldenringitems/VarresBouquet.png';
import VelvetSwordStTrinaIcon from '../../../icons/eldenringitems/VelvetSwordStTrina.png';
import VenomousFangIcon from '../../../icons/eldenringitems/VenomousFang.png';
import VerdigrisGreatshieldIcon from '../../../icons/eldenringitems/VerdigrisGreatshield.png';
import VeteransProthesisIcon from '../../../icons/eldenringitems/VeteransProthesis.png';
import VisageShieldIcon from '../../../icons/eldenringitems/VisageShield.png';
import VulgarMilitiaSawIcon from '../../../icons/eldenringitems/VulgarMilitiaSaw.png';
import VulgarMilitiaShotelIcon from '../../../icons/eldenringitems/VulgarMilitiaShotel.png';
import VykesWarSpearIcon from '../../../icons/eldenringitems/VykesWarSpear.png';
import WakizashiIcon from '../../../icons/eldenringitems/Wakizashi.png';
import WarhawksTalonIcon from '../../../icons/eldenringitems/WarhawksTalon.png';
import WarpedAxeIcon from '../../../icons/eldenringitems/WarpedAxe.png';
import WarpickIcon from '../../../icons/eldenringitems/Warpick.png';
import WatchdogsGreatswordIcon from '../../../icons/eldenringitems/WatchdogsGreatsword.png';
import WatchdogsStaffIcon from '../../../icons/eldenringitems/WatchdogsStaff.png';
import WeatheredStraightSwordIcon from '../../../icons/eldenringitems/WeatheredStraightSword.png';
import WhipIcon from '../../../icons/eldenringitems/Whip.png';
import WingedGreathornIcon from '../../../icons/eldenringitems/WingedGreathorn.png';
import WingedScytheIcon from '../../../icons/eldenringitems/WingedScythe.png';
import WingOfAstelIcon from '../../../icons/eldenringitems/WingOfAstel.png';
import WolfCrestShieldIcon from '../../../icons/eldenringitems/WolfCrestShield.png';
import WoodenGreatshieldIcon from '../../../icons/eldenringitems/WoodenGreatshield.png';
import ZweihanderIcon from '../../../icons/eldenringitems/Zweihander.png';

export type ERItem = {
    icon: string
    name: string
    weapon_type: WeaponType
    scaling: Stat[]
    ash_of_war: AshOfWar
    guard_boost: number
    damage: DamageType[]
    weight: number
    optain: WayToOptain[]
    location: Location[]
    id: number
}

enum WayToOptain {
    Remembrance = 'Remembrance',
    Bossdrop = 'Boss Reward',
    FindInWorld = 'Find in World',
    Buy = 'Buy',
    GuaranteedDrop = 'Guaranteed Drop',
    RandomDrop = 'Random Drop',
    StartingEquipment = 'Starting Equipment',
    StoneswordKey = 'Stonesword Key',
    QuestReward = 'Quest Reward',
    Painting = 'Painting',
}

export enum WeaponType {
    Dagger = 'Dagger',
    StraightSword = 'Straight Sword',
    Greatsword = 'Greatsword',
    ColossalSword = 'Colossal Sword',
    ThrustingSword = 'Thrusting Sword',
    HeavyThrustingSword = 'Heavy Thrusting Sword',
    CurvedSword = 'Curved Sword',
    CurvedGreatsword = 'Curved Greatsword',
    Katana = 'Katana',
    Twinblade = 'Twinblade',
    Axe = 'Axe',
    Greataxe = 'Greataxe',
    Hammer = 'Hammer',
    Flail = 'Flail',
    GreatHammer = 'Great Hammer',
    ColossalWeapon = 'Colossal Weapon',
    Spear = 'Spear',
    GreatSpear = 'Great Spear',
    Halberd = 'Halberd',
    Reaper = 'Reaper',
    Whip = 'Whip',
    Fist = 'Fist',
    Claw = 'Claw',
    LightBow = 'Light Bow',
    Bow = 'Bow',
    Greatbow = 'Greatbow',
    Crossbow = 'Crossbow',
    Ballista = 'Ballista',
    GlintstoneStaff = 'Glintstone Staff',
    SacredSeal = 'Sacred Seal',
    Torch = 'Torch',
    ThrustingShield = 'Thrusting Shield',
    HandToHandArt = 'Hand-to-Hand Art',
    ThrowingBlade = 'Throwing Blade',
    BackhandBlade = 'Backhand Blade',
    PerfumeBottle = 'Perfume Bottle',
    BeastClaw = 'Beast Claw',
    LightGreatsword = 'Light Greatsword',
    GreatKatana = 'Great Katana',
    SmallShield = 'Small Shield',
    MediumShield = 'Medium Shield',
    Greatshield = 'Greatshield',
}

enum Location {
    AinselRiver = 'Ainsel River',
    DeeprootDepths = 'Deeproot Depths',
    SiofraRiver = 'Siofra River',

    Limgrave = 'Limgrave',
    WeepingPeninsula = 'Weeping Peninsula',
    LiurniaOfTheLakes = 'Liurnia of the Lakes',
    Caelid = 'Caelid',
    Dragonbarrow = 'Dragonbarrow',
    AltusPlateau = 'Altus Plateau',
    MtGelmir = 'Mt. Gelmir',
    CapitalOutskirts = 'Capital Outskirts',
    Leyndell = 'Leyndell',
    MountaintopsOfTheGiants = 'Mountaintops of the Giants',
    CrumblingFaramAzula = 'Crumbling Faram Azula',
    ConsectratedSnowfield = 'Consectrated Snowfield',
    MiquellasHailigtree = 'Miquella\'s Hailigtree',

    GravesitePlain = 'Gravesite Plain',
    ScaduAltus = 'Scadu Altus',
    RauhBase = 'Rauh Base',
    AncientRuinsOfRauh = 'Ancient Ruins of Rauh',
    CeruleanCoast = 'Cerulean Coast',
    CharosHiddenGrave = 'Charo\'s Hidden Grave',
    JaggedPeak = 'Jagged Peak',
    AbyssalWoods = 'Abyssal Woods',
    FingerRuinsOfRhia = 'Finger Ruins of Rhia',
    EnirIlim = 'Enir Ilim',
    Hinterlands = 'Hinterlands',

    RoundtableHold = 'Roundtable Hold',
}

enum Stat {
    Str = 'Str',
    Dex = 'Dex',
    Int = 'Int',
    Fai = 'Fai',
    Arc = 'Arc',
}

enum AshOfWar {
    Standard = 'Standard',
    Heavy = 'Heavy',
    Keen = 'Keen',
    Quality = 'Quality',
    Fire = 'Fire',
    FlameArt = 'Flame Art',
    Lightning = 'Lightning',
    Sacred = 'Sacred',
    Magic = 'Magic',
    Cold = 'Cold',
    Poison = 'Poison',
    Blood = 'Blood',
    Occult = 'Occult',

    Unique = 'Unique',
    NoSkill = 'No Skill',
}

enum DamageType {
    Standard = 'Standard',
    Strike = 'Strike',
    Slash = 'Slash',
    Pierce = 'Pierce',
}

export const similarWeaponTypes = [
    [WeaponType.StraightSword, WeaponType.Greatsword, WeaponType.ColossalSword, WeaponType.LightGreatsword],
    [WeaponType.ThrustingSword, WeaponType.HeavyThrustingSword],
    [WeaponType.CurvedSword, WeaponType.CurvedGreatsword],
    [WeaponType.Katana, WeaponType.GreatKatana],
    [WeaponType.Axe, WeaponType.Greataxe],
    [WeaponType.Hammer, WeaponType.GreatHammer],
    [WeaponType.ColossalSword, WeaponType.ColossalWeapon],
    [WeaponType.Spear, WeaponType.GreatSpear],
    [WeaponType.Fist, WeaponType.HandToHandArt, WeaponType.Claw, WeaponType.BeastClaw],
    [WeaponType.LightBow, WeaponType.Bow, WeaponType.Greatbow, WeaponType.Crossbow, WeaponType.Ballista],
    [WeaponType.ThrustingShield, WeaponType.SmallShield, WeaponType.MediumShield, WeaponType.Greatshield],
    [WeaponType.SacredSeal, WeaponType.GlintstoneStaff],
]

const allEldenRingItemsNoId: any[] = [
    {
        icon: GreatKatanaIcon,
        name: "Great Katana",
        weapon_type: WeaponType.GreatKatana,
        scaling: [Stat.Str, Stat.Dex],
        ash_of_war: AshOfWar.Keen,
        guard_boost: 35,
        damage: [DamageType.Slash, DamageType.Pierce],
        weight: 9,
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: DragonHuntersGreatKatanaIcon,
        name: "Dragon-Hunter's Great Katana",
        weapon_type: WeaponType.GreatKatana,
        scaling: [Stat.Str, Stat.Dex],
        ash_of_war: AshOfWar.Unique,
        guard_boost: 37,
        damage: [DamageType.Slash, DamageType.Pierce],
        weight: 10.5,
        optain: [WayToOptain.Bossdrop],
        location: [Location.GravesitePlain],
    },
    {
        icon: RakshasasGreatKatanaIcon,
        name: "Rakshasa's Great Katana",
        weapon_type: WeaponType.GreatKatana,
        scaling: [Stat.Str, Stat.Dex],
        ash_of_war: AshOfWar.Unique,
        guard_boost: 35,
        damage: [DamageType.Slash, DamageType.Pierce],
        weight: 9.5,
        optain: [WayToOptain.Bossdrop],
        location: [Location.ScaduAltus],
    },
    {
        icon: MiladyIcon,
        name: "Milady",
        weapon_type: WeaponType.LightGreatsword,
        scaling: [Stat.Str, Stat.Dex],
        ash_of_war: AshOfWar.Keen,
        guard_boost: 36,
        damage: [DamageType.Standard, DamageType.Pierce],
        weight: 6.5,
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: LedasSwordIcon,
        name: "Leda's Sword",
        weapon_type: WeaponType.LightGreatsword,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        guard_boost: 35,
        weight: 7.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.EnirIlim],
    },
    {
        icon: RellanasTwinBladesIcon,
        name: "Rellana's Twin Blades",
        weapon_type: WeaponType.LightGreatsword,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 35,
        weight: 8,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Remembrance],
        location: [Location.GravesitePlain],
    },
    {
        icon: RedBearsClawIcon,
        name: "Red Bear's Claw",
        weapon_type: WeaponType.BeastClaw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 22,
        weight: 4,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash],
        optain: [WayToOptain.Bossdrop],
        location: [Location.GravesitePlain],
    },
    {
        icon: BeastClawIcon,
        name: "Beast Claw",
        weapon_type: WeaponType.BeastClaw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 20,
        weight: 3,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash],
        optain: [WayToOptain.Bossdrop],
        location: [Location.GravesitePlain],
    },
    {
        icon: FiresparkPerfumeBottleIcon,
        name: "Firespark Perfume Bottle",
        weapon_type: WeaponType.PerfumeBottle,
        scaling: [Stat.Dex],
        guard_boost: 15,
        weight: 1,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: ChillingPerfumeBottleIcon,
        name: "Chilling Perfume Bottle",
        weapon_type: WeaponType.PerfumeBottle,
        scaling: [Stat.Dex, Stat.Int],
        guard_boost: 15,
        weight: 1,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.FindInWorld],
        location: [Location.CharosHiddenGrave],
    },
    {
        icon: FrenzyflamePerfumeBottleIcon,
        name: "Frenzyflame Perfume Bottle",
        weapon_type: WeaponType.PerfumeBottle,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 1,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.FindInWorld],
        location: [Location.AbyssalWoods],
    },
    {
        icon: LightningPerfumeBottleIcon,
        name: "Lightning Perfume Bottle",
        weapon_type: WeaponType.PerfumeBottle,
        scaling: [Stat.Dex, Stat.Fai],
        guard_boost: 15,
        weight: 1,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.FindInWorld],
        location: [Location.CeruleanCoast],
    },
    {
        icon: DeadlyPoisonPerfumeBottleIcon,
        name: "Deadly Poison Perfume Bottle",
        weapon_type: WeaponType.PerfumeBottle,
        scaling: [Stat.Dex, Stat.Arc],
        guard_boost: 15,
        weight: 1,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard],
        optain: [WayToOptain.Buy],
        location: [Location.GravesitePlain],
    },
    {
        icon: BackhandBladeIcon,
        name: "Backhand Blade",
        weapon_type: WeaponType.BackhandBlade,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 15,
        weight: 2,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: SmithscriptCirqueIcon,
        name: "Smithscript Cirque",
        weapon_type: WeaponType.BackhandBlade,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 25,
        weight: 2,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: CursebladesCirqueIcon,
        name: "Curseblade's Cirque",
        weapon_type: WeaponType.BackhandBlade,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 45,
        weight: 4.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash],
        optain: [WayToOptain.RandomDrop],
        location: [Location.GravesitePlain, Location.EnirIlim, Location.ScaduAltus, Location.AbyssalWoods, Location.CharosHiddenGrave, Location.CeruleanCoast, Location.AncientRuinsOfRauh],
    },
    {
        icon: SmithscriptDaggerIcon,
        name: "Smithscript Dagger",
        weapon_type: WeaponType.ThrowingBlade,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: DryleafArtsIcon,
        name: "Dryleaf Arts",
        weapon_type: WeaponType.HandToHandArt,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 20,
        weight: 1,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop],
        location: [Location.ScaduAltus],
    },
    {
        icon: DanesFootworkIcon,
        name: "Dane's Footwork",
        weapon_type: WeaponType.HandToHandArt,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 20,
        weight: 1,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop],
        location: [Location.EnirIlim],
    },
    {
        icon: DuelingShieldIcon,
        name: "Dueling Shield",
        weapon_type: WeaponType.ThrustingShield,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 50,
        weight: 9,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: CarianThrustingShieldIcon,
        name: "Carian Thrusting Shield",
        weapon_type: WeaponType.ThrustingShield,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 55,
        weight: 10.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: NanayasTorchIcon,
        name: "Nanaya's Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 51,
        weight: 3.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.AbyssalWoods],
    },
    {
        icon: LamentingVisageIcon,
        name: "Lamenting Visage",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str],
        guard_boost: 48,
        weight: 2,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.CharosHiddenGrave],
    },
    {
        icon: TorchIcon,
        name: "Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 19,
        weight: 1.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Buy],
        location: [Location.Limgrave, Location.RoundtableHold],
    },
    {
        icon: BeastRepellentTorchIcon,
        name: "Beast-Repellent Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 19,
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Buy],
        location: [Location.Dragonbarrow],
    },
    {
        icon: SteelWireTorchIcon,
        name: "Steel-Wire Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 19,
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: SentrysTorchIcon,
        name: "Sentry's Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        guard_boost: 22,
        weight: 5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Buy],
        location: [Location.CapitalOutskirts],
    },
    {
        icon: GhostflameTorchIcon,
        name: "Ghostflame Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 19,
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.SiofraRiver],
    },
    {
        icon: StTrinasTorchIcon,
        name: "St Trina's Torch",
        weapon_type: WeaponType.Torch,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        guard_boost: 20,
        weight: 3,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ConsectratedSnowfield],
    },
    {
        icon: DryleafSealIcon,
        name: "Dryleaf Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: FireKnightsSealIcon,
        name: "Fire Knight's Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: SpiraltreeSealIcon,
        name: "Spiraltree Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Painting],
        location: [Location.GravesitePlain],
    },
    {
        icon: FingerSealIcon,
        name: "Finger Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy],
        location: [Location.RoundtableHold],
    },
    {
        icon: ErdtreeSealIcon,
        name: "Erdtree Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Fai],
        guard_boost: 15,
        weight: 0,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MtGelmir],
    },
    {
        icon: GoldenOrderSealIcon,
        name: "Golden Order Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 0,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.CapitalOutskirts],
    },
    {
        icon: GravelStoneSealIcon,
        name: "Gravel Stone Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.Leyndell],
    },
    {
        icon: GiantsSealIcon,
        name: "Giant's Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MountaintopsOfTheGiants],
    },
    {
        icon: GodslayersSealIcon,
        name: "Godslayer's Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.StoneswordKey, WayToOptain.FindInWorld],
        location: [Location.Limgrave],
    },
    {
        icon: ClawmarkSealIcon,
        name: "Clawmark Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 15,
        weight: 1.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.QuestReward],
        location: [Location.Caelid],
    },
    {
        icon: FrenziedFlameSealIcon,
        name: "Frenzied Flame Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 0,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.QuestReward],
        location: [Location.Leyndell],
    },
    {
        icon: DragonCommunionSealIcon,
        name: "Dragon Communion Seal",
        weapon_type: WeaponType.SacredSeal,
        scaling: [Stat.Fai, Stat.Arc],
        guard_boost: 15,
        weight: 0,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.StoneswordKey, WayToOptain.GuaranteedDrop],
        location: [Location.Limgrave],
    },
    {
        icon: StaffOfTheGreatBeyondIcon,
        name: "Staff of the Great Beyond",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.ScaduAltus],
    },
    {
        icon: MaternalStaffIcon,
        name: "Maternal Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int, Stat.Arc],
        guard_boost: 13,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop],
        location: [Location.ScaduAltus],
    },
    {
        icon: AstrologersStaffIcon,
        name: "Astrologer's Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: GlintstoneStaffIcon,
        name: "Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.StartingEquipment, WayToOptain.RandomDrop, WayToOptain.GuaranteedDrop],
        location: [Location.LiurniaOfTheLakes, Location.Limgrave],
    },
    {
        icon: AcademyGlintstoneStaffIcon,
        name: "Academy Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.QuestReward, WayToOptain.RandomDrop],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: DiggersStaffIcon,
        name: "Digger's Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 17,
        weight: 4.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Caelid, Location.LiurniaOfTheLakes, Location.AltusPlateau],
    },
    {
        icon: DemiHumanQueensStaffIcon,
        name: "Demi-Human Queen's Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: AzursGlintstoneStaffIcon,
        name: "Azur's Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 4,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: LusatsGlintstoneStaffIcon,
        name: "Lusat's Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 4,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop],
        location: [Location.Caelid],
    },
    {
        icon: CarianGlintstoneStaffIcon,
        name: "Carian Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: CarianGlintbladeStaffIcon,
        name: "Carian Glintblade Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 14,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: CarianRegalScepterIcon,
        name: "Carian Regal Scepter",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.Magic,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: AlbinauricStaffIcon,
        name: "Albinauric Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int, Stat.Arc],
        guard_boost: 14,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MtGelmir],
    },
    {
        icon: StaffOfLossIcon,
        name: "Staff of Loss",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 14,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: GelmirGlintstoneStaffIcon,
        name: "Gelmir Glintstone Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int, Stat.Fai],
        guard_boost: 14,
        weight: 2.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MtGelmir],
    },
    {
        icon: CrystalStaffIcon,
        name: "Crystal Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 17,
        weight: 4.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: RottenCrystalStaffIcon,
        name: "Rotten Crystal Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 17,
        weight: 4.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MiquellasHailigtree],
    },
    {
        icon: MeteoriteStaffIcon,
        name: "Meteorite Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int],
        guard_boost: 17,
        weight: 4.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: StaffOfTheGuiltyIcon,
        name: "Staff of the Guilty",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Fai],
        guard_boost: 17,
        weight: 4.5,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MountaintopsOfTheGiants, Location.LiurniaOfTheLakes, Location.MtGelmir],
    },
    {
        icon: PrinceOfDeathsStaffIcon,
        name: "Prince of Death's Staff",
        weapon_type: WeaponType.GlintstoneStaff,
        scaling: [Stat.Str, Stat.Int, Stat.Fai],
        guard_boost: 15,
        weight: 3,
        ash_of_war: AshOfWar.NoSkill,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.DeeprootDepths],
    },
    {
        icon: RabbathsCannonIcon,
        name: "Rabbath's Cannon",
        weapon_type: WeaponType.Ballista,
        scaling: [],
        guard_boost: 0,
        weight: 15,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: HandBallistaIcon,
        name: "Hand Ballista",
        weapon_type: WeaponType.Ballista,
        scaling: [],
        guard_boost: 0,
        weight: 10,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: JarCannonIcon,
        name: "Jar Cannon",
        weapon_type: WeaponType.Ballista,
        scaling: [],
        guard_boost: 0,
        weight: 15,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MtGelmir],
    },
    {
        icon: RepeatingCrossbowIcon,
        name: "Repeating Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 6,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: SpreadCrossbowIcon,
        name: "Spread Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: SoldiersCrossbowIcon,
        name: "Soldier's Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 3.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.LiurniaOfTheLakes, Location.Caelid, Location.AltusPlateau, Location.Leyndell, Location.MtGelmir, Location.ConsectratedSnowfield, Location.MiquellasHailigtree, Location.GravesitePlain, Location.ScaduAltus],
    },
    {
        icon: LightCrossbowIcon,
        name: "Light Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Buy],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: HeavyCrossbowIcon,
        name: "Heavy Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 5.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.LiurniaOfTheLakes, Location.Caelid, Location.WeepingPeninsula, Location.ConsectratedSnowfield, Location.DeeprootDepths, Location.Leyndell, Location.MiquellasHailigtree, Location.ScaduAltus, Location.RauhBase, Location.GravesitePlain, Location.AncientRuinsOfRauh, Location.CeruleanCoast, Location.CharosHiddenGrave, Location.AbyssalWoods],
    },
    {
        icon: ArbalestIcon,
        name: "Arbalest",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 6,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Limgrave],
    },
    {
        icon: CrepussBlackKeyCrossbowIcon,
        name: "Crepus's Black-Key Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.StoneswordKey, WayToOptain.FindInWorld],
        location: [Location.RoundtableHold],
    },
    {
        icon: PulleyCrossbowIcon,
        name: "Pulley Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MtGelmir],
    },
    {
        icon: FullMoonCrossbowIcon,
        name: "Full Moon Crossbow",
        weapon_type: WeaponType.Crossbow,
        scaling: [],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: IgonsGreatbowIcon,
        name: "Igon's Greatbow",
        weapon_type: WeaponType.Greatbow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 12.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.GuaranteedDrop, WayToOptain.QuestReward],
        location: [Location.GravesitePlain, Location.JaggedPeak],
    },
    {
        icon: GreatbowIcon,
        name: "Greatbow",
        weapon_type: WeaponType.Greatbow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 10,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld, WayToOptain.RandomDrop],
        location: [Location.AltusPlateau, Location.Caelid, Location.Leyndell, Location.ScaduAltus, Location.GravesitePlain, Location.RauhBase, Location.AbyssalWoods, Location.ConsectratedSnowfield, Location.DeeprootDepths],
    },
    {
        icon: GolemGreatbowIcon,
        name: "Golem Greatbow",
        weapon_type: WeaponType.Greatbow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 14.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.Caelid, Location.AltusPlateau, Location.WeepingPeninsula, Location.MountaintopsOfTheGiants],
    },
    {
        icon: ErdtreeGreatbowIcon,
        name: "Erdtree Greatbow",
        weapon_type: WeaponType.Greatbow,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        guard_boost: 0,
        weight: 11,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.StoneswordKey, WayToOptain.GuaranteedDrop],
        location: [Location.Limgrave],
    },
    {
        icon: LionGreatbowIcon,
        name: "Lion Greatbow",
        weapon_type: WeaponType.Greatbow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 9.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Remembrance],
        location: [Location.Caelid],
    },
    {
        icon: AnsbachsLongbowIcon,
        name: "Ansbach's Longbow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 4.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop, WayToOptain.QuestReward],
        location: [Location.EnirIlim],
    },
    {
        icon: AlbinauricBowIcon,
        name: "Albinauric Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 4.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.ConsectratedSnowfield],
    },
    {
        icon: BlackBowIcon,
        name: "Black Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Leyndell],
    },
    {
        icon: PulleyBowIcon,
        name: "Pulley Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MtGelmir],
    },
    {
        icon: HornBowIcon,
        name: "Horn Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        guard_boost: 0,
        weight: 4.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.SiofraRiver],
    },
    {
        icon: SerpentBowIcon,
        name: "Serpent Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        guard_boost: 0,
        weight: 3.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Dragonbarrow],
    },
    {
        icon: ErdtreeBowIcon,
        name: "Erdtree Bow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Leyndell],
    },
    {
        icon: LongbowIcon,
        name: "Longbow",
        weapon_type: WeaponType.Bow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 4,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy, WayToOptain.RandomDrop],
        location: [Location.RoundtableHold, Location.Limgrave, Location.LiurniaOfTheLakes, Location.WeepingPeninsula, Location.AltusPlateau, Location.MtGelmir],
    },
    {
        icon: BoneBowIcon,
        name: "Bone Bow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 3,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: ShortbowIcon,
        name: "Shortbow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 2.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy, WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.LiurniaOfTheLakes, Location.WeepingPeninsula, Location.MtGelmir, Location.Caelid, Location.MountaintopsOfTheGiants, Location.ScaduAltus, Location.ScaduAltus],
    },
    {
        icon: CompositeBowIcon,
        name: "Composite Bow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 3,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Buy],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: RedBranchShortbowIcon,
        name: "Red Branch Shortbow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 2.5,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Leyndell, Location.WeepingPeninsula, Location.LiurniaOfTheLakes],
    },
    {
        icon: MisbegottenShortbowIcon,
        name: "Misbegotten Shortbow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 3,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.WeepingPeninsula, Location.MiquellasHailigtree],
    },
    {
        icon: HarpBowIcon,
        name: "Harp Bow",
        weapon_type: WeaponType.LightBow,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 0,
        weight: 2,
        ash_of_war: AshOfWar.Standard,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Painting],
        location: [Location.AltusPlateau],
    },
    {
        icon: ClawsOfNightIcon,
        name: "Claws of Night",
        weapon_type: WeaponType.Claw,
        scaling: [Stat.Dex],
        guard_boost: 21,
        weight: 3,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.QuestReward, WayToOptain.Bossdrop],
        location: [Location.ScaduAltus],
    },
    {
        icon: HookclawsIcon,
        name: "Hookclaws",
        weapon_type: WeaponType.Claw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 20,
        weight: 2,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Limgrave],
    },
    {
        icon: BloodhoundClawsIcon,
        name: "Bloodhound Claws",
        weapon_type: WeaponType.Claw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 22,
        weight: 3,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.MtGelmir],
    },
    {
        icon: VenomousFangIcon,
        name: "Venomous Fang",
        weapon_type: WeaponType.Claw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 19,
        weight: 2.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: RaptorTalonsIcon,
        name: "Raptor Talons",
        weapon_type: WeaponType.Claw,
        scaling: [Stat.Str, Stat.Dex],
        guard_boost: 19,
        weight: 1.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.AltusPlateau],
    },
    {
        icon: PataIcon,
        name: "Pata",
        weapon_type: WeaponType.Fist,
        guard_boost: 26,
        scaling: [Stat.Str, Stat.Dex],
        weight: 4,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: GolemFistIcon,
        name: "Golem Fist",
        weapon_type: WeaponType.Fist,
        guard_boost: 36,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        weight: 5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.RauhBase],
    },
    {
        icon: ThiolliersHiddenNeedleIcon,
        name: "Thiollier's Hidden Needle",
        weapon_type: WeaponType.Fist,
        guard_boost: 18,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 0.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.QuestReward, WayToOptain.Bossdrop],
        location: [Location.EnirIlim],
    },
    {
        icon: PoisonedHandIcon,
        name: "Poisoned Hand",
        weapon_type: WeaponType.Fist,
        guard_boost: 19,
        scaling: [Stat.Str, Stat.Arc],
        weight: 1,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: MaddingHandIcon,
        name: "Madding Hand",
        weapon_type: WeaponType.Fist,
        guard_boost: 19,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        weight: 1,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.AbyssalWoods],
    },
    {
        icon: CaestusIcon,
        name: "Caestus",
        weapon_type: WeaponType.Fist,
        guard_boost: 20,
        scaling: [Stat.Str, Stat.Dex],
        weight: 1.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Buy],
        location: [Location.Limgrave],
    },
    {
        icon: SpikedCaestusIcon,
        name: "Spiked Caestus",
        weapon_type: WeaponType.Fist,
        guard_boost: 20,
        scaling: [Stat.Str, Stat.Dex],
        weight: 2,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Buy],
        location: [Location.Dragonbarrow],
    },
    {
        icon: KatarIcon,
        name: "Katar",
        weapon_type: WeaponType.Fist,
        guard_boost: 21,
        scaling: [Stat.Str, Stat.Dex],
        weight: 2.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: IronBallIcon,
        name: "Iron Ball",
        weapon_type: WeaponType.Fist,
        guard_boost: 22,
        scaling: [Stat.Str, Stat.Dex],
        weight: 2.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop, WayToOptain.QuestReward],
        location: [Location.LiurniaOfTheLakes, Location.AltusPlateau],
    },
    {
        icon: StarFistIcon,
        name: "Star Fist",
        weapon_type: WeaponType.Fist,
        guard_boost: 23,
        scaling: [Stat.Str, Stat.Dex],
        weight: 3,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Leyndell],
    },
    {
        icon: ClingingBoneIcon,
        name: "Clinging Bone",
        weapon_type: WeaponType.Fist,
        guard_boost: 21,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 3,
        ash_of_war: AshOfWar.Occult,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop], //! You need to optain Hailigtree Medalion Half, does that also count as a Quest?
        location: [Location.RoundtableHold],
    },
    {
        icon: VeteransProthesisIcon,
        name: "Veterans Prothesis",
        weapon_type: WeaponType.Fist,
        guard_boost: 24,
        scaling: [Stat.Str, Stat.Dex],
        weight: 4,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MountaintopsOfTheGiants],
    },
    {
        icon: CipherPataIcon,
        name: "Cipher Pata",
        weapon_type: WeaponType.Fist,
        guard_boost: 19,
        scaling: [Stat.Fai],
        weight: 0,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.RoundtableHold],
    },
    {
        icon: GraftedDragonIcon,
        name: "Grafted Dragon",
        weapon_type: WeaponType.Fist,
        guard_boost: 22,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.Limgrave],
    },
    {
        icon: ToothWhipIcon,
        name: "Tooth Whip",
        weapon_type: WeaponType.Whip,
        guard_boost: 16,
        scaling: [Stat.Str, Stat.Dex],
        weight: 3,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: WhipIcon,
        name: "Whip",
        weapon_type: WeaponType.Whip,
        guard_boost: 15,
        scaling: [Stat.Str, Stat.Dex],
        weight: 2,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: ThornedWhipIcon,
        name: "Thorned Whip",
        weapon_type: WeaponType.Whip,
        guard_boost: 15,
        scaling: [Stat.Str, Stat.Dex],
        weight: 2.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MountaintopsOfTheGiants],
    },
    {
        icon: UrumiIcon,
        name: "Urumi",
        weapon_type: WeaponType.Whip,
        guard_boost: 15,
        scaling: [Stat.Str, Stat.Dex],
        weight: 3,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Slash],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: HoslowsPetalWhipIcon,
        name: "Hoslow's Petal Whip",
        weapon_type: WeaponType.Whip,
        guard_boost: 17,
        scaling: [Stat.Str, Stat.Dex],
        weight: 3.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop, WayToOptain.QuestReward, WayToOptain.GuaranteedDrop],
        location: [Location.MountaintopsOfTheGiants, Location.LiurniaOfTheLakes],
    },
    {
        icon: MagmaWhipCandlestickIcon,
        name: "Magma Whip Candlestick",
        weapon_type: WeaponType.Whip,
        guard_boost: 15,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.QuestReward],
        location: [Location.MtGelmir],
    },
    {
        icon: GiantsRedBraidIcon,
        name: "Giant's Red Braid",
        weapon_type: WeaponType.Whip,
        guard_boost: 15,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 2.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.MountaintopsOfTheGiants],
    },
    {
        icon: ObsidianLaminaIcon,
        name: "Obsidian Lamina",
        weapon_type: WeaponType.Reaper,
        guard_boost: 28,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 6.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash],
        optain: [WayToOptain.QuestReward, WayToOptain.Bossdrop],
        location: [Location.EnirIlim],
    },
    {
        icon: ScytheIcon,
        name: "Scythe",
        weapon_type: WeaponType.Reaper,
        guard_boost: 30,
        scaling: [Stat.Str, Stat.Dex],
        weight: 7.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: GraveScytheIcon,
        name: "Grave Scythe",
        weapon_type: WeaponType.Reaper,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex],
        weight: 9.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash],
        optain: [WayToOptain.RandomDrop],
        location: [Location.LiurniaOfTheLakes, Location.Caelid, Location.GravesitePlain, Location.CeruleanCoast, Location.CharosHiddenGrave, Location.ScaduAltus],
    },
    {
        icon: HaloScytheIcon,
        name: "Halo Scythe",
        weapon_type: WeaponType.Reaper,
        guard_boost: 31,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 8.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Caelid, Location.MiquellasHailigtree],
    },
    {
        icon: WingedScytheIcon,
        name: "Winged Scythe",
        weapon_type: WeaponType.Reaper,
        guard_boost: 30,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 7.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash],
        optain: [WayToOptain.FindInWorld],
        location: [Location.WeepingPeninsula],
    },
    {
        icon: SpiritGlaiveIcon,
        name: "Spirit Glaive",
        weapon_type: WeaponType.Halberd,
        guard_boost: 39,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 6.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.CharosHiddenGrave],
    },
    {
        icon: PolebladeOfTheBudIcon,
        name: "Poleblade of the Bud",
        weapon_type: WeaponType.Halberd,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 10,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.Remembrance],
        location: [Location.AncientRuinsOfRauh],
    },
    {
        icon: HalberdIcon,
        name: "Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 42,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy],
        location: [Location.Limgrave],
    },
    {
        icon: BanishedKnightsHalberdIcon,
        name: "Banished Knight's Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 42,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.QuestReward, WayToOptain.Bossdrop, WayToOptain.RandomDrop, WayToOptain.GuaranteedDrop],
        location: [Location.LiurniaOfTheLakes, Location.WeepingPeninsula, Location.Limgrave, Location.Caelid, Location.MountaintopsOfTheGiants, Location.CrumblingFaramAzula],
    },
    {
        icon: LucerneIcon,
        name: "Lucerne",
        weapon_type: WeaponType.Halberd,
        guard_boost: 39,
        scaling: [Stat.Str, Stat.Dex],
        weight: 7,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: GlaiveIcon,
        name: "Glaive",
        weapon_type: WeaponType.Halberd,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex],
        weight: 10,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.JaggedPeak, Location.GravesitePlain, Location.Limgrave, Location.LiurniaOfTheLakes, Location.AltusPlateau, Location.WeepingPeninsula, Location.CrumblingFaramAzula, Location.MtGelmir],
    },
    {
        icon: VulgarMilitiaShotelIcon,
        name: "Vulgar Militia Shotel",
        weapon_type: WeaponType.Halberd,
        guard_boost: 42,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.LiurniaOfTheLakes, Location.MountaintopsOfTheGiants, Location.Dragonbarrow, Location.Caelid, Location.ScaduAltus, Location.GravesitePlain, Location.AbyssalWoods],
    },
    {
        icon: VulgarMilitiaSawIcon,
        name: "Vulgar Militia Saw",
        weapon_type: WeaponType.Halberd,
        guard_boost: 42,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.LiurniaOfTheLakes, Location.MountaintopsOfTheGiants, Location.Dragonbarrow, Location.Caelid, Location.ScaduAltus, Location.GravesitePlain, Location.AbyssalWoods],
    },
    {
        icon: GuardiansSwordspearIcon,
        name: "Guardian's Swordspear",
        weapon_type: WeaponType.Halberd,
        guard_boost: 44,
        scaling: [Stat.Str, Stat.Dex],
        weight: 9,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.WeepingPeninsula, Location.LiurniaOfTheLakes, Location.AltusPlateau, Location.Leyndell, Location.ConsectratedSnowfield, Location.Dragonbarrow],
    },
    {
        icon: NightriderGlaiveIcon,
        name: "Nightrider Glaive",
        weapon_type: WeaponType.Halberd,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 12,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: PestsGlaiveIcon,
        name: "Pest's Glaive",
        weapon_type: WeaponType.Halberd,
        guard_boost: 39,
        scaling: [Stat.Str, Stat.Dex],
        weight: 7,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Caelid, Location.AinselRiver, Location.MiquellasHailigtree, Location.GravesitePlain, Location.RauhBase, Location.AncientRuinsOfRauh, Location.ScaduAltus, Location.CharosHiddenGrave, Location.AbyssalWoods],
    },
    {
        icon: GargoylesHalberdIcon,
        name: "Gargoyle's Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 12,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.Leyndell],
    },
    {
        icon: GargoylesBlackHalberdIcon,
        name: "Gargoyle's Black Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 12,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.Dragonbarrow],
    },
    {
        icon: GoldenHalberdIcon,
        name: "Golden Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 52,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 13.5,
        ash_of_war: AshOfWar.Sacred,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.Limgrave],
    },
    {
        icon: DragonHalberdIcon,
        name: "Dragon Halberd",
        weapon_type: WeaponType.Halberd,
        guard_boost: 47,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 10.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.SiofraRiver],
    },
    {
        icon: LorettasWarSickleIcon,
        name: "Loretta's War Sickle",
        weapon_type: WeaponType.Halberd,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 10,
        ash_of_war: AshOfWar.Magic,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MiquellasHailigtree],
    },
    {
        icon: CommandersStandardIcon,
        name: "Commander's Standard",
        weapon_type: WeaponType.Halberd,
        guard_boost: 49,
        scaling: [Stat.Str, Stat.Dex],
        weight: 11.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.Caelid],
    },
    {
        icon: BloodfiendsSacredSpearIcon,
        name: "Bloodfiend's Sacred Spear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 30,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 8,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.RauhBase],
    },
    {
        icon: MessmerSoldiersSpearIcon,
        name: "Messmer Soldier's Spear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 43,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.ScaduAltus, Location.RauhBase, Location.AncientRuinsOfRauh, Location.GravesitePlain, Location.CeruleanCoast, Location.CharosHiddenGrave, Location.AbyssalWoods],
    },
    {
        icon: SpearOfTheImpalerIcon,
        name: "Spear of the Impaler",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 45,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 9.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Remembrance],
        location: [Location.ScaduAltus],
    },
    {
        icon: BarbedStaffSpearIcon,
        name: "Barbed Staff-Spear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 37,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 7.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.AbyssalWoods],
    },
    {
        icon: LanceIcon,
        name: "Lance",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 44,
        scaling: [Stat.Str, Stat.Dex],
        weight: 9,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Limgrave],
    },
    {
        icon: TreespearIcon,
        name: "Treespear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 45,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 9.5,
        ash_of_war: AshOfWar.Sacred,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: SerpentHunterIcon,
        name: "Serpent-Hunter",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 12,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.MtGelmir],
    },
    {
        icon: SiluriasTreeIcon,
        name: "Siluria's Tree",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 10,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.DeeprootDepths],
    },
    {
        icon: VykesWarSpearIcon,
        name: "Vyke's War Spear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 42,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 8,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: MohgwynsSacredSpearIcon,
        name: "Mohgwyn's Sacred Spear",
        weapon_type: WeaponType.GreatSpear,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 10,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Remembrance],
        location: [Location.SiofraRiver],
    },
    {
        icon: SmithscriptSpearIcon,
        name: "Smithscript Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 28,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Arc],
        weight: 4,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.ScaduAltus],
    },
    {
        icon: SwiftSpearIcon,
        name: "Swift Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 30,
        scaling: [Stat.Dex],
        weight: 3,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: BloodfiendsForkIcon,
        name: "Bloodfiend's Fork",
        weapon_type: WeaponType.Spear,
        guard_boost: 31,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 4.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.GravesitePlain, Location.RauhBase, Location.ScaduAltus, Location.CeruleanCoast],
    },
    {
        icon: ShortSpearIcon,
        name: "Short Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 30,
        scaling: [Stat.Str, Stat.Dex],
        weight: 4,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.StartingEquipment, WayToOptain.Buy, WayToOptain.RandomDrop],
        location: [Location.RoundtableHold, Location.Limgrave, Location.WeepingPeninsula, Location.Caelid, Location.AltusPlateau, Location.Leyndell, Location.GravesitePlain, Location.ScaduAltus, Location.CharosHiddenGrave],
    },
    {
        icon: IronSpearIcon,
        name: "Iron Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 31,
        scaling: [Stat.Str, Stat.Dex],
        weight: 4.5,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.SiofraRiver],
    },
    {
        icon: SpearIcon,
        name: "Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 31,
        scaling: [Stat.Str, Stat.Dex],
        weight: 4.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop, WayToOptain.GuaranteedDrop, WayToOptain.QuestReward, WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.LiurniaOfTheLakes, Location.MtGelmir, Location.AltusPlateau],
    },
    {
        icon: PartisanIcon,
        name: "Partisan",
        weapon_type: WeaponType.Spear,
        guard_boost: 36,
        scaling: [Stat.Str, Stat.Dex],
        weight: 6.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.Caelid, Location.LiurniaOfTheLakes, Location.Leyndell, Location.DeeprootDepths],
    },
    {
        icon: PikeIcon,
        name: "Pike",
        weapon_type: WeaponType.Spear,
        guard_boost: 39,
        scaling: [Stat.Str, Stat.Dex],
        weight: 7.5,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Limgrave],
    },
    {
        icon: SpikedSpearIcon,
        name: "Spiked Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 34,
        scaling: [Stat.Str, Stat.Dex],
        weight: 6,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Strike, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.WeepingPeninsula, Location.LiurniaOfTheLakes],
    },
    {
        icon: CrossNaginataIcon,
        name: "Cross-Naginata",
        weapon_type: WeaponType.Spear,
        guard_boost: 39,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Slash, DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: ClaymansHarpoonIcon,
        name: "Clayman's Harpoon",
        weapon_type: WeaponType.Spear,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 5.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.SiofraRiver, Location.AinselRiver],
    },
    {
        icon: CelebrantsRibRakeIcon,
        name: "Celebrant's Rib-Rake",
        weapon_type: WeaponType.Spear,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex],
        weight: 5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.AltusPlateau],
    },
    {
        icon: TorchpoleIcon,
        name: "Torchpole",
        weapon_type: WeaponType.Spear,
        guard_boost: 34,
        scaling: [Stat.Str, Stat.Dex],
        weight: 6,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave],
    },
    {
        icon: InquisitorsGirandoleIcon,
        name: "Inquisitor's Girandole",
        weapon_type: WeaponType.Spear,
        guard_boost: 37,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 7.5,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MtGelmir],
    },
    {
        icon: CrystalSpearIcon,
        name: "Crystal Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 5.5,
        ash_of_war: AshOfWar.Keen,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Caelid],
    },
    {
        icon: RottenCrystalSpearIcon,
        name: "Rotten Crystal Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 5.5,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MiquellasHailigtree],
    },
    {
        icon: CleanrotSpearIcon,
        name: "Cleanrot Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 33,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 5.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Caelid, Location.MiquellasHailigtree],
    },
    {
        icon: DeathRitualSpearIcon,
        name: "Death Ritual Spear",
        weapon_type: WeaponType.Spear,
        guard_boost: 36,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 6.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MountaintopsOfTheGiants],
    },
    {
        icon: BoltOfGransaxIcon,
        name: "Bolt of Gransax",
        weapon_type: WeaponType.Spear,
        guard_boost: 40,
        scaling: [Stat.Str, Stat.Dex],
        weight: 8.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.FindInWorld],
        location: [Location.Leyndell],
    },
    {
        icon: BloodfiendsArmIcon,
        name: "Bloodfiend's Arm",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 51,
        scaling: [Stat.Str, Stat.Dex, Stat.Arc],
        weight: 20,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.GravesitePlain],
    },
    {
        icon: AnvilHammerIcon,
        name: "Anvil Hammer",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 53,
        scaling: [Stat.Str, Stat.Dex, Stat.Int, Stat.Fai],
        weight: 22,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.GravesitePlain],
    },
    {
        icon: DevoniasHammerIcon,
        name: "Devonia's Hammer",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 51,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 20,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.AncientRuinsOfRauh],
    },
    {
        icon: ShadowSunflowerBlossomIcon,
        name: "Shadow Sunflower Blossom",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 16,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.ScaduAltus],
    },
    {
        icon: GazingFingerIcon,
        name: "Gazing Finger Icon",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Int, Stat.Fai],
        weight: 15,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Remembrance],
        location: [Location.ScaduAltus],
    },
    {
        icon: DuelistGreataxeIcon,
        name: "Duelist Greataxe",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 52,
        scaling: [Stat.Str, Stat.Dex],
        weight: 20,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Leyndell, Location.AltusPlateau],
    },
    {
        icon: RottenGreataxeIcon,
        name: "Rotten Greataxe",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 52,
        scaling: [Stat.Str, Stat.Dex],
        weight: 20,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.ConsectratedSnowfield],
    },
    {
        icon: GolemsHalberdIcon,
        name: "Golem's Halberd",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 21.5,
        ash_of_war: AshOfWar.Quality,
        damage: [DamageType.Standard, DamageType.Pierce],
        optain: [WayToOptain.RandomDrop],
        location: [Location.Limgrave, Location.AltusPlateau, Location.Leyndell, Location.MountaintopsOfTheGiants],
    },
    {
        icon: GiantCrusherIcon,
        name: "Giant-Crusher",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 58,
        scaling: [Stat.Str],
        weight: 26.5,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.AltusPlateau],
    },
    {
        icon: PrelatesInfernoCrozierIcon,
        name: "Prelate's Inferno Crozier",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 55,
        scaling: [Stat.Str, Stat.Dex],
        weight: 23.5,
        ash_of_war: AshOfWar.FlameArt,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.MtGelmir],
    },
    {
        icon: GreatClubIcon,
        name: "Great Club",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 47,
        scaling: [Stat.Str, Stat.Fai],
        weight: 17,
        ash_of_war: AshOfWar.Sacred,
        damage: [DamageType.Strike],
        optain: [WayToOptain.Bossdrop, WayToOptain.StoneswordKey],
        location: [Location.AltusPlateau],
    },
    {
        icon: TrollsHammerIcon,
        name: "Troll's Hammer",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 46,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 16,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld, WayToOptain.StoneswordKey],
        location: [Location.AltusPlateau],
    },
    {
        icon: DragonGreatclawIcon,
        name: "Dragon Greatclaw",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 47,
        scaling: [Stat.Str, Stat.Dex],
        weight: 16,
        ash_of_war: AshOfWar.Heavy,
        damage: [DamageType.Standard],
        optain: [WayToOptain.Bossdrop],
        location: [Location.AltusPlateau],
    },
    {
        icon: WatchdogsStaffIcon,
        name: "Watchdog's Staff",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 19,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.FindInWorld],
        location: [Location.LiurniaOfTheLakes],
    },
    {
        icon: StaffOfTheAvatarIcon,
        name: "Staff of the Avatar",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 52,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 20,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop],
        location: [Location.DeeprootDepths],
    },
    {
        icon: RottenStaffIcon,
        name: "Rotten Staff",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 18,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.GuaranteedDrop, WayToOptain.RandomDrop],
        location: [Location.MiquellasHailigtree],
    },
    {
        icon: EnvoysGreathornIcon,
        name: "Envoy's Greathorn",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex, Stat.Fai],
        weight: 19.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Strike],
        optain: [WayToOptain.RandomDrop],
        location: [Location.MiquellasHailigtree],
    },
    {
        icon: GhizasWheelIcon,
        name: "Ghiza's Wheel",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 48,
        scaling: [Stat.Str, Stat.Dex],
        weight: 19,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MtGelmir],
    },
    {
        icon: FallingstarBeastJawIcon,
        name: "Fallingstar Beast Jaw",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex, Stat.Int],
        weight: 21.5,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Pierce],
        optain: [WayToOptain.Bossdrop],
        location: [Location.MtGelmir],
    },
    {
        icon: AxeOfGodfreyIcon,
        name: "Axe of Godfrey",
        weapon_type: WeaponType.ColossalWeapon,
        guard_boost: 50,
        scaling: [Stat.Str, Stat.Dex],
        weight: 18,
        ash_of_war: AshOfWar.Unique,
        damage: [DamageType.Standard],
        optain: [WayToOptain.Remembrance],
        location: [Location.Leyndell],
    },
]

export const allEldenRingItems: ERItem[] = allEldenRingItemsNoId.map((item, index) => {return {...item, id: index}})
